<div class="row">
  <div class="col-md-12">

    <div class="box">
      <div class="box-body">
        <div class="row">
          <div class="col-md-11">
            <h4 *ngIf="!isRevenda" class="box-title">
              Consulte as empresas que já possuem token de licenças gerados ou utilize a opção gerar token.
            </h4>
            <h4 *ngIf="isRevenda" class="box-title">
              Suas licenças são liberadas através de lotes. Abaixo estão os lotes gerados para sua revenda.
            </h4>
          </div>
          <div class="col-md-1">
            <button type="submit" *ngIf="!isRevenda" class="btn btn-primary pull-right"
              [routerLink]="['adicionar']">Gerar
              Token</button>
          </div>
        </div>
      </div>
      <!-- /.box-header -->
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <div class="btn-group" role="group" aria-label="...">
              <button type="button" class="btn btn-primary" (click)="getLote(7)">7 dias</button>
              <button type="button" class="btn btn-primary" (click)="getLote(15)">15 dias</button>
              <button type="button" class="btn btn-primary" (click)="getLote(30)">30 dias</button>
              <!-- <button type="button" class="btn btn-primary" (click)="getLote(null)">Todos</button> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table id="example1" datatable class="datatable table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Cod Lote</th>
                  <th>Cod Revendedor</th>
                  <th>Razão Social/Nome</th>
                  <th>Produto/Oferta</th>
                  <th>Data</th>
                  <th>Licenciados?</th>
                  <th>Quantidade</th>
                  <th>Ativos</th>
                  <th *ngIf="!isRevenda">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tkn of loteList">
                  <td>{{tkn.idlote}}</td>
                  <td>{{tkn.revendedor.idrevendedor}}</td>
                  <td>{{tkn.revendedor.nmrevendedor}}</td>
                  <td>{{tkn.produto ? tkn.produto.nmproduto : tkn.oferta ? tkn.oferta.nmoferta : ''}}</td>
                  <td>{{tkn.dtinicio | date: 'dd/MM/yyyy'}}</td>
                  <td>{{tkn.fllicenciado == true ? 'SIM' : 'NÃO'}}</td>
                  <td>{{tkn.nbquantidadelicencas}}</td>
                  <td>{{tkn.totalAtivos}}</td>
                  <td *ngIf="!isRevenda">
                    <nobr>
                      <a href="" class="btn btn-sm btn-primary" title="Consulta" label="Consulta"
                        [routerLink]="[ 'consulta', tkn.idlote ]"><span class="fa fa-search"></span></a>&nbsp;
                    </nobr>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.box-body -->
</div>


<!-- /.row -->