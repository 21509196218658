import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Produto } from 'src/app/model/Produto';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<Produto[]> {
    return this.http.get<Produto[]>(`${environment.apiUrl}/view/produto`);
  }

  findById(id: number) {
    return this.http.get(`${environment.apiUrl}/view/produto/${id}`);
  }

  findByIdExample(id: number) { }

  save(produto: Produto) {
    if (produto.idproduto !== undefined) {
      return this.http.put(`${environment.apiUrl}/view/produto`, produto);
    }

      return this.http.post(`${environment.apiUrl}/view/produto`, produto);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/view/produto/${id}`);
  }
}
