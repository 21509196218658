<div class="box">
  <div class="box-body">
    <div class="row">
      <div class="col-md-11">
        <h4 class="box-title">Aqui poderá ser realizada a consulta, edição, exclusão e adicionar um novo parceiro.
        </h4>
      </div>
      <div class="col-md-1">
        <a [routerLink]="['adicionar']" class="btn btn-primary pull-right">Adicionar</a>
      </div>
    </div>
  </div>
  <!-- /.box-header -->
  <div class="box-body">
    <table id="revendaList" class="table table-bordered table-striped">
      <thead>
        <tr>
          <!-- <th><input type="checkbox"></th> -->
          <th>#</th>
          <th>Razão Social/Nome</th>
          <th>Código Distribuidor</th>
          <th>CNPJ/CPF</th>
          <th>Data Cadastro</th>
          <th>Contato</th>
          <th>Ações</th>
      </thead>
      <tbody>
        <tr *ngFor="let rev of revendedorList">
          <!-- <td><input type="checkbox"></td> -->
          <td>{{rev.idrevendedor}}</td>
          <td>{{rev.nmrevendedor}}</td>
          <td>{{rev.iddistribuidor}}</td>
          <td>{{rev.cpfcnpj}}</td>
          <td>{{rev.dtcriacao | date:'dd/MM/yyyy'}}</td>
          <td>{{rev.pessoa.nmpessoa}}</td>
          <td>
            <nobr>
              <a class="btn btn-sm btn-primary" title="Editar" [routerLink]="[ 'editar', rev.idrevendedor ]"><span
                  class="fa fa-pencil"></span></a>&nbsp;
              <a class="btn btn-sm btn-danger" title="Excluir" (click)="excluir(rev)"><span
                  class="fa fa-trash"></span></a>&nbsp;
              <a class="btn btn-sm btn-info" title="Usuários" [routerLink]="[ 'usuarios', rev.idrevendedor ]"><span
                  class="fa fa-users"></span></a>
            </nobr>
          </td>
        </tr>
      </tbody>
      <tfoot>
      </tfoot>
    </table>
  </div>
</div>
<!-- /.box-body -->



<!-- /.row -->