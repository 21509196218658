<div class="row">
  <div class="col-md-12">
    <div class="box">
      <form #frmProduto="ngForm" novalidate>
        <div class="box-header with-border">
          <h3 class="box-title">Informações principais</h3>
        </div>
        <!-- /.box-header -->
        <div class="box-body">
          <div class="row">
            <div class="col-md-3">
              <img class="img-thumbnail" src="{{produto.dsimagem}}" alt="User profile picture">
              <div class="form-group">
                <label for="dsimagem">Imagem do produto</label>
                <input type="file" id="dsimagem" name="dsimagem" #dsimagem  (change)="getImage()" accept="image/png, image/jpeg">
                <p class="help-block">Imagem com 500px x 500px</p>
              </div>
            </div>
          </div>
          <!-- /.col -->

          <div class="row form-group"
            [ngClass]="{'has-error': nmproduto.invalid && (nmproduto.dirty || nmproduto.touched)}">
            <div class="col-md-12">
              <label>Nome do produto</label>
              <input type="text" class="form-control" [(ngModel)]="produto.nmproduto" #nmproduto="ngModel"
                id="nmproduto" name="nmproduto" placeholder="" required>
              <span class="help-block" *ngIf="nmproduto.invalid && (nmproduto.dirty || nmproduto.touched)">
                Nome do produto é obrigatório
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group"
                [ngClass]="{'has-error': idfabricantedistribuidor.invalid && (idfabricantedistribuidor.dirty || idfabricantedistribuidor.touched)}">
                <label>Fabricante</label> 
                <select class="form-control select2" [(ngModel)]="produto.idfabricantedistribuidor" required
                  name="idfabricantedistribuidor" style="width: 100%;" #idfabricantedistribuidor="ngModel" tabindex="-1"
                  aria-hidden="true">
                  <option selected="selected">SELECIONE</option>
                  <option *ngFor="let fabricante of fabricanteDistribuidorList"
                    value="{{fabricante.idfabricantedistribuidor}}">
                    {{fabricante.fabricante.nmfabricante}}
                  </option>
                </select>
                <span class="help-block"
                  *ngIf="idfabricantedistribuidor.invalid && (idfabricantedistribuidor.dirty || idfabricantedistribuidor.touched)">
                  Fabricante é obrigatório
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group"
                [ngClass]="{'has-error': cdproduto.invalid && (cdproduto.dirty || cdproduto.touched)}">
                <label>MPN</label>
                <button type="button" class="btn btn-box-tool" data-toggle="tooltip" title="Código junto ao fabricante">
                  <i class="fa fa-question-circle"></i></button>
                <input type="text" class="form-control" [(ngModel)]="produto.cdproduto" name="cdproduto"
                  id="cdproduto" #cdproduto="ngModel" placeholder="" required>
                <span class="help-block" *ngIf="cdproduto.invalid && (cdproduto.dirty || cdproduto.touched)">
                  MPN é obrigatório
                </span>
              </div>
            </div>
            <div class="col-md-4">

              <label>NCM</label>
              <button type="button" class="btn btn-box-tool" data-toggle="tooltip" title="Número Comum do Mercosul">
                <i class="fa fa-question-circle"></i></button>
              <input type="text" class="form-control" [(ngModel)]="produto.nbncm" name="nbncm" #nbncm="ngModel" placeholder="">
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group"
                [ngClass]="{'has-error': nbperiodo.invalid && (nbperiodo.dirty || nbperiodo.touched)}">
                <label>Duração do produto</label>
                <select class="form-control select2" [(ngModel)]="produto.nbperiodo" name="nbperiodo"
                  #nbperiodo="ngModel" style="width: 100%;" tabindex="-1" aria-hidden="true" required>
                  <option value="1" selected="selected">1 mês</option>
                  <option value="3">3 meses</option>
                  <option value="6">6 meses</option>
                  <option value="12">12 meses</option>
                  <option value="24">24 meses</option>
                  <option value="36">36 meses</option>
                </select>
                <span class="help-block" *ngIf="nbperiodo.invalid && (nbperiodo.dirty || nbperiodo.touched)">
                  Período do produto é obrigatório
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Preço de Custo</label>
                <input type="text" class="form-control" [(ngModel)]="produto.vlcusto" name="vlcusto" #vlcusto="ngModel" currencyMask
                  [options]="{ prefix: '', thousands: '.', decimal: ',' }" placeholder="">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Preço de Venda</label>
                <input type="text" class="form-control" [(ngModel)]="produto.vlvenda" currencyMask name="vlvenda" #vlvenda="ngModel"
                  [options]="{ prefix: '', thousands: '.', decimal: ',' }" placeholder="">
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-12">
              <label>Link de download</label>
              <input type="text" class="form-control" [(ngModel)]="produto.dslink" #dslink="ngModel"
                id="dslink" name="dslink" placeholder="">
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-12">
              <label>Descrição completa</label>
              <textarea class="textarea" [(ngModel)]="produto.dsproduto" placeholder="Place some text here" name="dsproduto" #dsproduto="ngModel"
                style="width: 100%; height: 200px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;"></textarea>
            </div>
          </div>


        </div>
        <!-- /.box-body -->
        <div class="box-footer">
          <div class="row">
            <div class="col-md-1 col-md-offset-10">
              <button type="button" class="btn btn-primary pull-right" [disabled]="frmProduto.invalid"
                (click)="gravar()">GRAVAR</button>
            </div>
            <div class="col-md-1">
              <button type="button" class="btn btn-default pull-right"
                [routerLink]="[ '/', 'main', 'produto' ]">VOLTAR</button>
            </div>
          </div>
        </div>
      </form>
    </div>