import { Component, OnInit } from '@angular/core';
import { Revendedor } from 'src/app/model/Revendedor';
import { Pessoa } from 'src/app/model/Pessoa';
import { Produto } from 'src/app/model/Produto';
import { Oferta } from 'src/app/model/Oferta';
import { ProdutoService } from 'src/app/service/Produto.service';
import { OfertaService } from 'src/app/service/Oferta.service';
import { Revendedoroferta } from 'src/app/model/Revendedoroferta';
import { Revendedorproduto } from 'src/app/model/Revendedorproduto';
import { RevendedorService } from 'src/app/service/Revendedor.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { Usuario } from 'src/app/model/Usuario';
import { EnderecoService } from 'src/app/service/Endereco.service';
import { Endereco } from 'src/app/model/Endereco';

@Component({
  selector: 'app-adicionar-revenda',
  templateUrl: './adicionar-revenda.component.html',
  styleUrls: ['./adicionar-revenda.component.css']
})
export class AdicionarRevendaComponent implements OnInit {
  produtosList: Produto[];
  produto: Produto = new Produto();
  revendedor: Revendedor = new Revendedor();
  pessoa: Pessoa = new Pessoa();
  produtoList: Produto[] = [];
  ofertaList: Oferta[] = [];
  produtosAdicionados: Produto[] = [];
  ofertasAdicionadas: Oferta[] = [];
  oferta: Oferta = new Oferta();
  endereco: Endereco = new Endereco();
  usuario: Usuario = <Usuario>JSON.parse(sessionStorage.getItem('user'));

  constructor(private produtoService: ProdutoService,
    private ofertaService: OfertaService,
    private revendedorService: RevendedorService,
    private router: Router,
    private route: ActivatedRoute,
    private enderecoService: EnderecoService) {
      this.getProdutoList();
      this.getOfertaList();
    }

  ngOnInit() {
    this.getUpdateData();
  }

  getUpdateData() {
    if (this.route.snapshot.params.idrevendedor) {
      this.revendedorService.findById(this.route.snapshot.params.idrevendedor).subscribe(rev => {
        this.revendedor = <Revendedor>rev;
        this.pessoa = this.revendedor.pessoa;

        if (this.produtosList && this.revendedor.revendaProduto) {
          this.revendedor.revendaProduto.forEach(revProd => {
            const _produto = this.produtosList.filter((element) => element.idproduto == revProd.idproduto)[0];
            _produto.dtinicio = revProd.dtinicio;
            _produto.dtfim = revProd.dtfim;
            this.produtosAdicionados.push(_produto);
          });
        }

        if (this.ofertaList && this.revendedor.revendaOferta) {
          this.revendedor.revendaOferta.forEach(revOfer => {
            const _oferta = this.ofertaList.filter((element) => element.idoferta == revOfer.idoferta)[0];
            _oferta.dtinicio = revOfer.dtinicio;
            _oferta.dtfim = revOfer.dtfim;
            this.ofertasAdicionadas.push(_oferta);
          });
        }
      });

      // produtosAdicionados
      // ofertasAdicionadas
    }
  }

  getProdutoList() {
    this.produtoService.findAll().subscribe(resposta => {
      this.produtosList = resposta;
      if (this.produtosList) {
        this.produtosList = this.produtosList.filter((element) => element.dtfim == null);
      }
    });
  }

  getOfertaList() {
    this.ofertaService.findAll().subscribe(resposta => {
      this.ofertaList = resposta;
      if (this.ofertaList) {
        this.ofertaList = this.ofertaList.filter((element) => element.dtfim == null);
      }
    });
  }

  adicionarProduto(produto: Produto) {
    // this.produtoList = this.produtoList.filter((element) => element.idproduto == this.produto.idproduto);
    this.produtosAdicionados.push(produto);
  }

  removerProduto(produto) {
    this.produtoList = this.produtoList.filter((element) => element.idproduto === this.produto.idproduto);
    this.produtosAdicionados.splice(this.produtosAdicionados.indexOf(produto), 1);
  }

  adicionarOferta(oferta: Oferta) {
    // this.ofertaList = this.ofertaList.filter((element) => element.idoferta == this.oferta.idoferta);
    this.ofertasAdicionadas.push(oferta);
  }

  removerOferta(oferta) {
    this.ofertaList = this.ofertaList.filter((element) => element.idoferta === this.oferta.idoferta);
    this.ofertasAdicionadas.splice(this.ofertasAdicionadas.indexOf(oferta), 1);
  }

  gravarDados() {
    this.revendedor.revendaProduto = [];
    this.revendedor.revendaOferta = [];
    this.revendedor.pessoa = this.pessoa;

    this.revendedor.iddistribuidor = this.usuario.iddistribuidor;

    // Prepara os produtos a serem adicionados a oferta
    for (const produto of this.produtosAdicionados) {
      const revendaProduto: Revendedorproduto = new Revendedorproduto();
      revendaProduto.idproduto = produto.idproduto;
      revendaProduto.dtinicio = produto.dtinicio;
      revendaProduto.dtfim = produto.dtfim;
      this.revendedor.revendaProduto.push(revendaProduto);
    }

    for (const oferta of this.ofertasAdicionadas) {
      const revendaOferta: Revendedoroferta = new Revendedoroferta();
      revendaOferta.idoferta = oferta.idoferta;
      revendaOferta.dtinicio = oferta.dtinicio;
      revendaOferta.dtfim = oferta.dtfim;
      this.revendedor.revendaOferta.push(revendaOferta);
    }

    this.revendedorService.save(this.revendedor).subscribe(
      success => {
        Swal.fire('Sucesso', 'Dados cadastrados com sucesso!', 'success')
          .then(() => {
            this.router.navigate(['/', 'main', 'revenda']);
          });
      },
      error => {
        Swal.fire('Erro', 'Ocorreu um erro na gravação!\n' + JSON.stringify(error), 'error');
      }
    );
  }

  buscaCep() {
    const cepFormatado = this.revendedor.dscep.substr(0, 5) + '-' + this.revendedor.dscep.substr(5, 3);
    this.enderecoService.find(cepFormatado).subscribe(
      success => {
        this.endereco = <Endereco>success;
        this.revendedor.dslogradouro = this.endereco.dsTipoLogradouro + ' ' + this.endereco.dsLogradouro;
        this.revendedor.dscidade = this.endereco.dsCidade;
        this.revendedor.dsuf = this.endereco.dsUF;
        this.revendedor.dsbairro = this.endereco.dsBairro;
        this.revendedor.idpais = 1;
        document.getElementById('nbendereco').focus();
      }
    );
  }
}
