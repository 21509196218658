<div class="row">
  <div class="col-md-12">
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Selecione o revendedor ou o produto que deseja consultar</h3>
      </div>
      <div class="box-body">
        <div class="row form-group">
          
          <div class="col-md-4">
            <label>Revendedor</label>
            <select class="form-control select2" [(ngModel)]="relatorio.revenda.idrevendedor"
              (change)="getProdutoOferta()" style="width: 100%;" tabindex="-1" aria-hidden="true">
              <option value="">TODOS</option>
              <option *ngFor="let rev of revendedorList" [ngValue]="rev.idrevendedor">{{rev.nmrevendedor}}</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Produto</label>
            <select class="form-control select2" [(ngModel)]="relatorio.produto.idproduto" style="width: 100%;"
              tabindex="-1" aria-hidden="true">
              <option value="">TODOS</option>
              <option *ngFor="let prod of produtoList" [ngValue]="prod.idproduto">{{prod.nmproduto}}</option>
            </select>
          </div>

          <div class="col-md-4">
            <label>Fabricante</label>
            <select class="form-control select2" [(ngModel)]="relatorio.fabricante.idfabricante" style="width: 100%;"
              tabindex="-1" aria-hidden="true">
              <option value="">TODOS</option>
              <option *ngFor="let fabricante of fabricanteDistribuidorList" value="{{fabricante.idfabricante}}">
                {{fabricante.fabricante.nmfabricante}}
              </option>
            </select>
          </div>

        </div>

        <div class="row form-group">

          <div class="col-md-4">
            <label>Data Início</label>
            <input type="date" class="form-control" [(ngModel)]="relatorio.dtInicio" name="dtInicio" #dtInicio="ngModel"
              placeholder="" required>
          </div>

          <div class="col-md-4">
            <label>Data Fim</label>
            <input type="date" class="form-control" [(ngModel)]="relatorio.dtFim" name="dtFim" #dtFim="ngModel"
              placeholder="" required>
          </div>

          <div class="col-md-4">
            <label>Token/Licença</label>
            <input type="text" class="form-control" [(ngModel)]="relatorio.chave" name="chave" #chave="ngModel"
              placeholder="" required>
          </div>

        </div>
        <div class="row form-group">
          <div class="col-md-4">
            <label>Situação &nbsp;</label>
            <input type="checkbox" (click)="$event.target.checked == false ? relatorio.situacao.splice(relatorio.situacao.indexOf('A'), 1) : relatorio.situacao.push('A')" value="A" name="situacao"
              placeholder="" required> Ativo &nbsp;
            <input type="checkbox" (click)="$event.target.checked == false ? relatorio.situacao.splice(relatorio.situacao.indexOf('P'), 1) : relatorio.situacao.push('P')" value="P" name="situacao"
              placeholder="" required> Pendente &nbsp;
            <input type="checkbox" (click)="$event.target.checked == false ? relatorio.situacao.splice(relatorio.situacao.indexOf('C'), 1) : relatorio.situacao.push('C')" value="C" name="situacao"
              placeholder="" required> Cancelado &nbsp;
          </div>

          <div class="col-md-4">
            <label>&nbsp; </label>
            <button (click)="consultar()" class="btn btn-success">CONSULTAR</button>
          </div>

        </div>

      </div>
    </div>
  </div>
  <div class="col-md-12" *ngIf="licencasList.length > 0">
    <div class="box">
      <div class="box-header">
        <div class="box-title">
          <label> Resultados (Total de registros: {{totalRecords}}) </label>
        </div>
      </div>
      <div class="box-body">

        <table class="table table-bordered table-striped" datatables="ng">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Produto</th>
              <th>Token</th>
              <th>Licença</th>
              <th>Revenda</th>
              <th>Data Ativação</th>
              <th>Data Expiração</th>
              <th>Situação</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let lic of licencasList">
              <td>{{lic.ativacao?.pessoa?.nmpessoa}} {{lic.ativacao?.pessoa?.dssobrenome}}</td>
              <td>{{lic.produto?.nmproduto}}</td>
              <td>{{lic.cdlicenca}}</td>
              <td>{{lic.ativacao?.dsgeneratedkey}}</td>
              <td>{{lic.revenda?.nmrevendedor}}</td>
              <td>{{lic.ativacao?.dtinicio | date: 'dd/MM/yyyy' }}</td>
              <td>{{lic.ativacao?.dtfim | date: 'dd/MM/yyyy' }}</td>
              <td>{{lic.stlicenca == 'A' ? 'Ativo' : lic.stlicenca == 'C' ? 'Cancelado' : 'Pendente' }}</td>
              <td>
                <a class="btn btn-sm btn-danger" *ngIf="lic.stlicenca != 'C'" title="Cancelar"
                  (click)="cancelar(lic)"><span class="fa fa-trash"></span></a>
                <span class="label label-info" *ngIf="lic.stlicenca == 'C'">Cancelado</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- /.box-body -->
    </div>
    <!-- /.row -->
    <div class="row">
      <div class="col-md-12">
        <button type="submit" class="btn btn-primary pull-right" [routerLink]="[ '/', 'main', 'token' ]">Voltar</button>
      </div>
    </div>
  </div>
</div>