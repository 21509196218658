import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxCurrencyModule } from 'ngx-currency';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { ComponentsComponent } from './components/components.component';
import { BreadcrumbComponent } from './common/breadcrumb/breadcrumb.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { LoginComponent } from './common/login/login.component';
import { FooterComponent } from './common/footer/footer.component';
import { RevendaComponent } from './custom/revenda/revenda.component';
import { PainelComponent } from './custom/painel/painel.component';
import { ParceiroComponent } from './custom/parceiro/parceiro.component';
import { ProdutoComponent } from './custom/produto/produto.component';
import { RelatorioComponent } from './custom/relatorio/relatorio.component';
import { FabricanteComponent } from './custom/fabricante/fabricante.component';
import { OfertaComponent } from './custom/oferta/oferta.component';
import { AdicionarRevendaComponent } from './custom/revenda/adicionar-revenda/adicionar-revenda.component';
import { AdicionarProdutoComponent } from './custom/produto/adicionar-produto/adicionar-produto.component';
import { TokenComponent } from './custom/token/token.component';
import { AdicionarTokenComponent } from './custom/token/adicionar-token/adicionar-token.component';
import { ConsultaTokenComponent } from './custom/token/consulta-token/consulta-token.component';
import { AdicionarOfertaComponent } from './custom/oferta/adicionar-oferta/adicionar-oferta.component';
import { MainComponent } from './common/main/main.component';
import { PainelDetailComponent } from './custom/painel/painel-detail/painel-detail.component';
import { PerfilComponent } from './custom/perfil/perfil.component';
import { LoaderComponent } from './common/loader/loader.component';
import { LoaderService } from './common/loader/loader.service';
import { LoaderInterceptor } from './common/loader/loader.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UsuarioRevendaComponent } from './custom/revenda/usuario-revenda/usuario-revenda.component';
import { UsuarioRevendaCadastroComponent } from './custom/revenda/usuario-revenda/usuario-revenda-cadastro/usuario-revenda-cadastro.component';
import { LinkPagamentoComponent } from './custom/link-pagamento/link-pagamento.component';
import { RelatorioChaveOfflineComponent } from './custom/relatorio-chave-offline/relatorio-chave-offline.component';
import { RevendaCytomicComponent } from './custom/revenda-cytomic/revenda-cytomic.component';
import { AdicionarRevendaCytomicComponent } from './custom/revenda-cytomic/adicionar-revenda/adicionar-revenda-cytomic.component';
import { UsuarioRevendaCytomicComponent } from './custom/revenda-cytomic/usuario-revenda/usuario-revenda-cytomic.component';
import { UsuarioRevendaCytomicCadastroComponent } from './custom/revenda-cytomic/usuario-revenda/usuario-revenda-cadastro/usuario-revenda-cytomic-cadastro.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './nova-senha/nova-senha.component';
import { DataTablesModule } from 'angular-datatables';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ComponentsComponent,
    BreadcrumbComponent,
    SidebarComponent,
    LoginComponent,
    FooterComponent,
    PainelComponent,
    ParceiroComponent,
    ProdutoComponent,
    RelatorioComponent,
    FabricanteComponent,
    OfertaComponent,
    RevendaComponent,
    AdicionarRevendaComponent,
    AdicionarProdutoComponent,
    UsuarioRevendaComponent,
    UsuarioRevendaCadastroComponent,
    TokenComponent,
    AdicionarTokenComponent,
    ConsultaTokenComponent,
    AdicionarOfertaComponent,
    MainComponent,
    PainelDetailComponent,
    PerfilComponent,
    LoaderComponent,
    LinkPagamentoComponent,
    RelatorioChaveOfflineComponent,
    RevendaCytomicComponent,
    AdicionarRevendaCytomicComponent,
    UsuarioRevendaCytomicComponent,
    UsuarioRevendaCytomicCadastroComponent,
    RecuperarSenhaComponent,
    NovaSenhaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxCurrencyModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    NgxMaskModule.forRoot(),
    DataTablesModule
  ],
  providers: [LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
