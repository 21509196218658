<div class="row">
  <div class="col-md-12">
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Informações principais</h3>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row form-group">
              <div class="col-md-12">
                <label>Nome do fabricante</label>
                <input type="text" class="form-control" [(ngModel)]="fabricante.nmfabricante" id="nmfabricante"
                  placeholder="">
              </div>
              <div class="col-md-12">
                <label>Codigo do fabricante (usado na integração)</label>
                <input type="text" class="form-control" [(ngModel)]="fabricante.codigo" id="codigo" placeholder="">
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-4">
                <label>Data início</label>
                <input type="date" class="form-control" [(ngModel)]="fabricante.dtinicio" placeholder="">
              </div>
              <div class="col-md-4">
                <label>Data fim</label>
                <input type="date" class="form-control" [(ngModel)]="fabricante.dtfim" placeholder="">
              </div>
            </div>
          </div>
        </div>
        <div class="box-footer col-md-12">
          <button type="submit" class="btn btn-primary pull-right" (click)="gravar()">GRAVAR</button>
        </div>
      </div>

    </div>
    <div class="box">
      <div class="box-header">
        <h3 class="box-title">Lista de fabricantes</h3>
      </div>
      <div class="box-body">
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th><input type="checkbox"></th>
              <th>#</th>
              <th>Nome do Fabricante</th>
              <th>Código</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let fab of fabricanteList">
              <td><input type="checkbox"></td>
              <td>{{fab.idfabricante}}</td>
              <td>{{fab.nmfabricante}}</td>
              <td>{{fab.codigo}}</td>
              <td>{{fab.dtinicio | date: "dd/MM/yyyy"}}</td>
              <td>{{fab.dtfim | date: "dd/MM/yyyy"}}</td>
              <td>
                <button class="btn btn-sm btn-primary" (click)="editar(fab)"><span
                    class="fa fa-pencil"></span></button>&nbsp;
                <button class="btn btn-sm btn-danger" (click)="excluir(fab)"><span class="fa fa-trash"></span></button>
              </td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>