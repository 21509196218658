<div class="row">
  <div class="col-md-12">

    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Dados do Revendedor</h3>
      </div>
      <div class="box-body">
        <div class="row form-group">
          <div class="col-md-3">
            <label>Código</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.idrevendedor" name="idrevendedor"
              #idrevendedor="ngModel" [disabled]="true" placeholder="">
          </div>
          <div class="col-md-3">
            <label>CNPJ/CPF</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.cpfcnpj" name="cpfcnpj" #cpfcnpj="ngModel" mask="CPF_CNPJ">
          </div>
          <div class="col-md-6">
            <label>Razão Social/Nome</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.nmrevendedor" name="nmrevendedor"
              #nmrevendedor="ngModel" placeholder="">
          </div>

        </div>
        <div class="row form-group">
          <div class="col-md-4">
            <label>CEP</label>
            <input mask="00000-000" [(ngModel)]="revendedor.dscep" name="dscep" #dscep="ngModel" (blur)="buscaCep()" type="tel" class="form-control" />
            <!-- <input type="text" class="form-control" [(ngModel)]="revendedor.dscep" name="dscep" #dscep="ngModel" placeholder=""> -->
          </div>
          <div class="col-md-4">
            <label>Logradouro</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.dslogradouro" name="dslogradouro"
              #dslogradouro="ngModel" placeholder="">
          </div>
          <div class="col-md-4">
            <label>Número</label>
            <input type="text" id="nbendereco" class="form-control" [(ngModel)]="revendedor.nbendereco"
              name="nbendereco" #nbendereco="ngModel" placeholder="">
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-4">
            <label>Cidade</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.dscidade" name="dscidade"
              #dscidade="ngModel" placeholder="">
          </div>
          <div class="col-md-4">
            <label>Estado</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.dsuf" name="dsuf" #dsuf="ngModel"
              placeholder="">
          </div>
          <div class="col-md-4">
            <label>País</label>
            <select class="form-control select2" [(ngModel)]="revendedor.idpais" name="idpais" #idpais="ngModel"
              style="width: 100%;" aria-hidden="true">
              <option value="1" selected="selected">Brasil</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-4">
            <label>Complemento</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.dscomplemento" name="dscomplemento"
              #dscomplemento="ngModel" placeholder="">
          </div>
          <div class="col-md-4">
            <label>E-mail</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.dsemail" name="dsemail" #dsemail="ngModel"
              placeholder="">
          </div>
          <div class="col-md-4">
            <label>Chave API</label>
            <input type="text" class="form-control" [(ngModel)]="revendedor.cdrevendedorapi" name="cdrevendedorapi" #cdrevendedorapi="ngModel"
              placeholder="">
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- box-body -->
    </div>
    <!-- box -->

    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Contato do Revendedor</h3>
      </div>
      <div class="box-body">
        <div class="row form-group">
          <div class="col-md-4">
            <label>Nome</label>
            <input type="text" class="form-control" [(ngModel)]="pessoa.nmpessoa" name="nmpessoa" #nmpessoa="ngModel"
              placeholder="">
          </div>
          <div class="col-md-4">
            <label>Sobrenome</label>
            <input type="text" class="form-control" [(ngModel)]="pessoa.dssobrenome" name="dssobrenome"
              #dssobrenome="ngModel" placeholder="">
          </div>
          <div class="col-md-4">
            <label>E-mail </label>
            <input type="text" class="form-control" [(ngModel)]="pessoa.dsemail" name="dsemail" #dsemail="ngModel"
              placeholder="">
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-4">
            <label>Tel Comercial</label>
            <input type="tel" mask="(00) 0000-0000 || (00) 00000-0000" [(ngModel)]="pessoa.dstelefonecomercial" name="dstelefonecomercial" #dstelefonecomercial="ngModel" class="form-control">
            <!-- <input type="text" class="form-control" [(ngModel)]="pessoa.dstelefonecomercial" name="dstelefonecomercial" #dstelefonecomercial="ngModel" placeholder=""> -->
          </div>
          <div class="col-md-4">
            <label>Ramal</label>
            <input type="text" class="form-control" [(ngModel)]="pessoa.dsramal" name="dsramal" #dsramal="ngModel"
              placeholder="">
          </div>
          <div class="col-md-4">
            <label>Tel Celular</label>
            <input type="tel" mask="(00) 0000-0000 || (00) 00000-0000" class="form-control" [(ngModel)]="pessoa.dstelefone" name="dstelefone" #dstelefone="ngModel"/>
            <!-- <input type="text" class="form-control" [(ngModel)]="pessoa.dstelefone" name="dstelefone" #dstelefone="ngModel" placeholder=""> -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- box-body -->
    </div>
    <!-- box -->


    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Produto</h3>
      </div>
      <div class="box-body">
        <div class="row form-group">
          <div class="col-md-4">
            <label>Selecione</label>
            <select class="form-control select2" [(ngModel)]="produto" name="selProduto" #selProduto="ngModel"
              style="width: 100%;" tabindex="-1" aria-hidden="true">
              <option value="" selected="selected">SELECIONE</option>
              <option *ngFor="let prod of produtosList" [ngValue]="prod">{{prod.nmproduto}}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Data início</label>
            <input type="date" class="form-control" [(ngModel)]="produto.dtinicio" name="dtinicio" #dtinicio="ngModel"
              placeholder="" required>
          </div>
          <div class="col-md-3">
            <label>Data fim</label>
            <input type="date" class="form-control" [(ngModel)]="produto.dtfim" name="dtfim" #dtfim="ngModel"
              placeholder="" required>
          </div>
          <div class="col-md-2">
            <br>
            <button (click)="adicionarProduto(produto)" type="submit"
              class="btn btn-primary pull-right">Adicionar</button>
          </div>
        </div>
      </div>
      <!-- /.box-body -->
    </div>

    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Produtos Selecionados</h3>
      </div>
      <div class="box-body">

        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <!-- <th><input type="checkbox"></th> -->
              <th>Cód do Produto</th>
              <th>MPN</th>
              <th>Descrição</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let prodAd of produtosAdicionados">
              <!-- <td><input type="checkbox"></td> -->
              <td>{{prodAd.idproduto}}</td>
              <td>{{prodAd.cdproduto}}</td>
              <td>{{prodAd.nmproduto}}</td>
              <td>{{prodAd.dtinicio | date: 'dd/MM/yyyy'}}</td>
              <td>{{prodAd.dtfim | date: 'dd/MM/yyyy'}}</td>
              <td>
                <button (click)="removerProduto(prodAd)" class="btn btn-sm btn-danger" title="Excluir" label="Ver">
                  <span class="fa fa-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Oferta</h3>
      </div>
      <div class="box-body">
        <div class="row form-group">
          <div class="col-md-4">
            <label>Selecione</label>
            <select class="form-control select2" [(ngModel)]="oferta" name="selOferta" #selOferta="ngModel"
              style="width: 100%;" tabindex="-1" aria-hidden="true">
              <option value="" selected="selected">SELECIONE</option>
              <option *ngFor="let ofr of ofertaList" [ngValue]="ofr">{{ofr.nmoferta}}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Data início</label>
            <input type="date" class="form-control" [(ngModel)]="oferta.dtinicio" name="dtinicioOferta"
              #dtinicioOferta="ngModel" placeholder="re" required>
          </div>
          <div class="col-md-3">
            <label>Data fim</label>
            <input type="date" class="form-control" [(ngModel)]="oferta.dtfim" name="dtfimOferta" #dtfimOferta="ngModel"
              placeholder="" required>
          </div>
          <div class="col-md-2">
            <br>
            <button (click)="adicionarOferta(oferta)" type="submit"
              class="btn btn-primary pull-right">Adicionar</button>
          </div>
        </div>
      </div>
      <!-- /.box-body -->
    </div>

    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Ofertas Selecionadas</h3>
      </div>
      <div class="box-body">

        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <!-- <th><input type="checkbox"></th> -->
              <th>Cód da Oferta</th>
              <th>Descrição</th>
              <th>Data Início</th>
              <th>Data Fim</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ofr of ofertasAdicionadas">
              <!-- <td><input type="checkbox"></td> -->
              <td>{{ofr.idoferta}}</td>
              <td>{{ofr.nmoferta}}</td>
              <td>{{ofr.dtinicio | date: 'dd/MM/yyyy'}}</td>
              <td>{{ofr.dtfim | date: 'dd/MM/yyyy'}}</td>
              <td>
                <button (click)="removerOferta(ofr)" class="btn btn-sm btn-danger" title="Excluir" label="Ver">
                  <span class="fa fa-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- /.row -->
    <div class="row">
      <div class="col-md-11">
        <button type="submit" (click)="gravarDados()" class="btn btn-primary pull-right">Cadastrar</button>
      </div>
      <div class="col-md-1">
          <button type="button" [routerLink]="[ '/', 'main', 'revenda' ]" class="btn btn-default">Voltar</button>
      </div>
    </div>
  </div>

</div>