import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Licenca } from '../model/Licenca';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient) { }

  cancelar(licenca: Licenca): Observable<any> {
    const dados = {idrevendedor: licenca.idrevendedor, idpessoa: licenca.ativacao.idpessoa, idtransacao: licenca.ativacao.idtransacao};

    return this.http.post(`${environment.apiUrl}/general/cancelar`, dados);

  }
}
