import { Component, OnInit } from '@angular/core';
import { Fabricante } from 'src/app/model/Fabricante';
import { FabricanteService } from 'src/app/service/Fabricante.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fabricante',
  templateUrl: './fabricante.component.html',
  styleUrls: ['./fabricante.component.css']
})
export class FabricanteComponent implements OnInit {

  fabricante: Fabricante = new Fabricante();
  fabricanteList: Fabricante[];

  constructor(private fabricanteService: FabricanteService) { }

  ngOnInit() {
    this.listaFabricantes();
  }


  listaFabricantes() {
    this.fabricanteService.findAll().subscribe(data => this.fabricanteList = data
      , error => {
            Swal.fire('', 'ERRO AO CONSULTAR FABRICANTES', 'error');
            console.log('erro consulta fabricante', error);
            });
  }

  gravar() {
    this.fabricanteService.save(this.fabricante)
    .subscribe(data => Swal.fire('', 'FABRICANTE CADASTRADO COM SUCESSO', 'success')
                      .then(success => this.listaFabricantes(), error => console.log('erro >>', error)) ,
              error => console.log('error >> ', error));
  }

  editar(fabricante: Fabricante) {
    this.fabricante = {...fabricante};
    const element = document.getElementById('nmfabricante');
    element.focus();
  }

  excluir(fabricante: Fabricante) {
    this.fabricanteService.delete(fabricante.idfabricante).subscribe(
        data => {
                  Swal.fire('', 'FABRICANTE EXCLUÍDO COM SUCESSO', 'success');
                  this.listaFabricantes();
                }
      , error => Swal.fire('', 'ERRO AO EXCLUIR O FABRICANTE', 'error')
    );
  }
}
