
<form #frmTeste="ngForm">
  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">Selecione o Revendedor, para qual os tokens deverão ser gerados</h3>
        </div>
        <div class="box-body">
          <div class="row form-group">
            <div class="col-md-4">
              <label>Revendedor</label>
              <select class="form-control select2" [(ngModel)]="lote.idrevendedor" required (change)="getProdutoOferta()" name="idrevendedor"  style="width: 100%;" tabindex="-1"
                aria-hidden="true">
                <option *ngFor="let rev of revendedorList" [ngValue]="rev.idrevendedor">{{rev.nmrevendedor}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Produto ou Oferta</label>
              <select class="form-control select2" [(ngModel)]="tipoToken" name="tipoToken" style="width: 100%;" tabindex="-1" aria-hidden="true">
                <option value="produto" selected="selected">Produto</option>
                <option value="oferta">Oferta</option>
              </select>
            </div>

            <div class="col-md-4" *ngIf="tipoToken == 'produto'">
              <label>Produto</label>
              <select class="form-control select2" [(ngModel)]="lote.idproduto" #idproduto="ngModel" required name="idproduto" [ngClass]="{'has-error': idproduto.invalid && (idproduto.dirty || idproduto.touched)}" style="width: 100%;" tabindex="-1" aria-hidden="true">
                <option *ngFor="let prod of produtoList" [ngValue]="prod.idproduto">{{prod.nmproduto}}</option>
              </select>
            </div>
            <div class="col-md-4" *ngIf="tipoToken == 'oferta'">
              <label>Oferta</label>
              <select class="form-control select2" [(ngModel)]="lote.idoferta" #idoferta="ngModel" required name="idoferta" [ngClass]="{'has-error': idoferta.invalid && (idoferta.dirty || idoferta.touched)}" style="width: 100%;" tabindex="-1" aria-hidden="true">
                <option *ngFor="let ofr of ofertaList" [ngValue]="ofr.idoferta">{{ofr.nmoferta}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="box">
        <div class="box-header">
          <div class="box-title">
            <label> Informações do Token </label>
          </div>
        </div>
        <div class="box-body">
          <div class="row form-group">
            <div class="col-md-4">
              <label>Quantidade de Token</label>
              <input type="number" [(ngModel)]="lote.nbquantidadelicencas" #nbquantidadelicencas="ngModel" required name="nbquantidadelicencas" [ngClass]="{'has-error': nbquantidadelicencas.invalid && (nbquantidadelicencas.dirty || nbquantidadelicencas.touched)}" class="form-control" placeholder="">
            </div>
            <div class="col-md-2">
              <input type="radio" name="optionsRadios" id="optionsRadios2" [(ngModel)]="lote.fllicenciado" (ngValue)="true">
              Licenciados
            </div>
            <div class="col-md-2">
              <input type="radio" name="optionsRadios" id="optionsRadios3" [(ngModel)]="lote.fllicenciado" (ngValue)="false">
              Não Licenciados
            </div>
            <div class="col-md-4">
              
              <button [disabled]="frmTeste.form.invalid" type="submit" (click)="gravarDados()" class="btn btn-primary pull-right">Gerar Token</button>
            </div>
          </div>
        </div>
        <!-- /.box-body -->
      </div>
      <!-- /.row -->
      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary pull-right" [routerLink]="[ '/', 'main', 'token' ]">Voltar</button>
        </div>
      </div>
    </div>
  </div>
</form>